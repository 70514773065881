type EventCallback = (...args: any[]) => void;

export class EventBus {
  private listeners: Map<string, EventCallback[]>;

  constructor() {
    this.listeners = new Map();
  }

  subscribe(eventType: string, callback: EventCallback): void {
    if (!this.listeners.has(eventType)) {
      this.listeners.set(eventType, []);
    }

    this.listeners.get(eventType)!.push(callback);
  }

  unsubscribe(eventType: string, callback: EventCallback): void {
    if (!this.listeners.has(eventType)) return;

    const eventListeners = this.listeners.get(eventType);

    if (eventListeners) {
      const index = eventListeners.indexOf(callback);
      if (index > -1) {
        eventListeners.splice(index, 1);
      }

      if (eventListeners.length === 0) {
        this.listeners.delete(eventType);
      }
    }
  }

  emit(eventType: string, ...args: any[]): void {
    if (!this.listeners.has(eventType)) return;

    const eventListeners = this.listeners.get(eventType);
    if (eventListeners) {
      [...eventListeners].forEach((callback) => callback(...args));
    }
  }

  clear(eventType?: string): void {
    if (eventType) {
      this.listeners.delete(eventType);
    } else {
      this.listeners.clear();
    }
  }
}
