import React from 'react';
import { ButtonProps, Button as CuiButton } from 'antd';
import clsx from 'clsx';

import Flex from 'components/ui/atoms/flex/Flex';

import classes from './Button.module.scss';
import { getClassNameVariant } from './helpers/getClassNameVariant';
import ButtonNonUi from './ButtonNonUi';

export type TButtonVariant = 'secondary' | 'accent' | 'success' | 'error' | 'purple' | 'accent-alt' | 'ghost'
  | 'no-bg' | 'no-bg-success' | 'no-bg-error';

const noBgVariants: TButtonVariant[] = ['no-bg', 'no-bg-success', 'no-bg-error'];

type TProps = ButtonProps & {
  variant?: TButtonVariant;
};

export default function Button(props: TProps) {
  const {
    className, variant, children, icon, ...rest
  } = props;

  if (variant && noBgVariants.includes(variant)) {
    return (
      <ButtonNonUi
        className={clsx(classes.button_type_no_bg, {
          [classes.button_type_no_bg_disabled]: rest.disabled,
          [classes.button_type_no_bg_success]: variant === 'no-bg-success',
          [classes.button_type_no_bg_error]: variant === 'no-bg-error',
        }, className)}
        {...rest}
      >
        <Flex align="center" gap={6}>
          {icon}
          {children}
        </Flex>
      </ButtonNonUi>
    );
  }

  return (
    <CuiButton
      className={clsx(classes.button, getClassNameVariant(variant), className)}
      icon={icon}
      {...rest}
    >
      {children}
    </CuiButton>
  );
}
