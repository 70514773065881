import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Scrollbars from 'react-custom-scrollbars-2';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { sidebarEventBus, SidebarEventBusEvent } from 'eventBus/instances/sidebar';

import Flex from 'components/ui/atoms/flex/Flex';
import IconButton from 'components/ui/atoms/iconButton/IconButton';
import classes from 'components/ui/organisms/sidebar/Sidebar.module.scss';
import { useSidebarViewModel } from 'components/ui/organisms/sidebar/viewModel/context';
import UserSection from 'components/ui/organisms/sidebar/ui/userSection/UserSection';
import Logo from 'components/ui/organisms/sidebar/ui/logo/Logo';
import LinksContainer from 'components/ui/organisms/sidebar/ui/linksContainer/LinksContainer';
import ChevronLeftIcon from 'components/ui/atoms/icons/new/ChevronLeft';

import { isPrimaryBg } from 'utils/routes/isPrimaryBg';

import TogglesContainer from './ui/togglesContainer/TogglesContainer';

type TProps = {
  onCollapseButtonClick: () => void;
  insideDrawer?: boolean;
}

function SidebarView(props: TProps) {
  const { onCollapseButtonClick, insideDrawer } = props;

  const { pathname } = useLocation();

  const {
    sidebarWidth, isSidebarCollapsed, setIsSidebarCollapsed,
  } = useSidebarViewModel();

  const withShadow = isPrimaryBg(pathname);

  useEffect(() => {
    if (insideDrawer) setIsSidebarCollapsed(false);
  }, [insideDrawer]);

  useEffect(() => {
    sidebarEventBus.subscribe(SidebarEventBusEvent.COLLAPSE, () => {
      setIsSidebarCollapsed(true);
    });

    return () => {
      sidebarEventBus.clear();
    };
  }, []);

  return (
    <Scrollbars
      renderTrackVertical={(props) => <div {...props} style={{ display: 'none' }} />}
      renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}
      className={clsx(classes.sidebar, {
        [classes.sidebar_with_shadow]: withShadow,
      })}
      style={{ maxWidth: `${sidebarWidth}px` }}
    >
      <div className={classes.sidebar__inner_container}>
        <Logo />
        <IconButton
          size="middle"
          icon={(
            <ChevronLeftIcon className={clsx(classes.sidebar__collapse_button__icon, {
              [classes.sidebar__collapse_button__icon_is_sidebar_collapsed]: isSidebarCollapsed,
            })}
            />
          )}
          className={classes.sidebar__collapse_button}
          onClick={onCollapseButtonClick}
        />
        <Flex
          vertical
          className={clsx(classes.content_container, {
            [classes.content_container_is_sidebar_collapsed]: isSidebarCollapsed,
          })}
        >
          <LinksContainer />
          <TogglesContainer />
          <UserSection />
        </Flex>
      </div>
    </Scrollbars>
  );
}

export default observer(SidebarView);
