import { BaseLocalStorageRepository } from './BaseLocalStorageRepository';

export class SidebarRepository extends BaseLocalStorageRepository {
  private readonly keyCollapsed: string = 'isSidebarCollapsed';

  public isSidebarCollapsed() {
    return this.get(this.keyCollapsed) === 'true';
  }

  public setIsSidebarCollapsed(value: boolean) {
    this.set(this.keyCollapsed, value.toString());
  }

  public toggleIsSidebarCollapsed() {
    const isSidebarCollapsed = this.isSidebarCollapsed();

    this.setIsSidebarCollapsed(!isSidebarCollapsed);
  }
}

export const sidebarRepository = new SidebarRepository();
