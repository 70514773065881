import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import { Button } from 'antd';
import clsx from 'clsx';

import classes from 'components/ui/atoms/iconButton/IconButton.module.scss';

type TProps = ButtonProps & {
  variant?: 'default' | 'accent' | 'danger';
  shape?: 'circle';
  withBackground?: boolean;
};

export default function IconButton(props: TProps) {
  const {
    variant, shape, withBackground, className, ...rest
  } = props;

  return (
    <Button
      type="text"
      {...rest}
      className={clsx(classes.icon_button, className, {
        [classes.icon_button_accent]: variant === 'accent',
        [classes.icon_button_danger]: variant === 'danger',
        [classes.icon_button_shape_circle]: shape === 'circle',
        [classes.with_background]: withBackground,
      })}
    />
  );
}
