import React, { ForwardedRef, forwardRef } from 'react';
import { Flex as CuiFlex, FlexProps } from 'antd';

type TProps = FlexProps;

function Flex(props: TProps, ref: ForwardedRef<HTMLDivElement>) {
  const { children, ...rest } = props;

  return (
    <CuiFlex {...rest} ref={ref}>{children}</CuiFlex>
  );
}

export default forwardRef(Flex);
